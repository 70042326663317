import { Formik } from 'formik';
import React from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import * as actions from '../actions/entity_actions';
import CheckBox from '../common/CheckBox';
import RadioButton from '../common/RadioButton';
import TextField from '../common/TextField';

const querystring = require('querystring');


class Preferences extends React.Component {
  componentDidMount() {
    this.props.getEntityByField('public-user', 'token', this.props.match.params.token);
  }

  constructor(props) {
    super(props)
    this.state = { saved: false }
  }

  submitPreferences = (values) => {
    console.log(values)
    this.setState({ saved: true })
    window.setTimeout(() => { this.setState({ saved: false }) }, 2000)
    this.props.updateEntity('public-user', values, 'none')
  }

  setupUserTags() {
    this.props.public_user.userTags = {}
    this.props.public_user.user_tags.forEach((userTag) => {
      this.props.public_user.userTags[userTag.id] = userTag.enabled
    })
  }

  renderAllTags() {
    const groups = new Map();
    if (this.props.public_user.user_tags) {
      if (!this.props.public_user.userTags) this.setupUserTags()
      this.props.public_user.user_tags.forEach((userTag) => {
        if (groups[userTag.tag.group]) {
          groups[userTag.tag.group].push(userTag)
        } else {
          groups[userTag.tag.group] = [userTag]
        }
      })
      const r = []
      Object.keys(groups).forEach((key) => {
        r.push(this.renderGroup(key, groups[key]))
      })

      return r
    }
    return ''
  }

  renderGroup(group, tags) {
    return (
      <Row>
        <Col>
          <Card className="mb-3">
            <Card.Header>{group}</Card.Header>
            <Card.Body>
              <Card.Text>
                {this.renderTags(tags)}
              </Card.Text>

            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }

  renderMailPref() {
    return (
      <Row>
        <Col>
          <Card className="mb-3">
            <Card.Header>Notification Preference</Card.Header>
            <Card.Body>
              <Card.Text>
                <RadioButton id="post" label="Every Post" value="POST" name="mail_pref" />
                <RadioButton id="weekly" label="Weekly Digest" value="WEEKLY" name="mail_pref" />
                <RadioButton id="monthly" label="Monthly Digest" value="MONTHLY" name="mail_pref" />
                <RadioButton id="none" label="None" value="NONE" name="mail_pref" />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }

  renderAccount() {
    if (!this.props.public_user.user) {
      return (
        <Row>
          <Col sm={6}>
            <Card className="mb-3">
              <Card.Header>Account Info</Card.Header>
              <Card.Body>
                <Card.Text>
                  <TextField name="email" type="email" label="Email" disabled />
                  <TextField name="password" type="password" label="Password*" required />

                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )
    }
    return ''
  }


  renderTags(tags) {
    return tags.map((tag) => <CheckBox id={tag.id} label={tag.tag.tag} value="1" name={`userTags[${tag.id}]`} />)
  }

  savedMessage() {
    if (this.state.saved) {
      return (<b>User Preferences Saved</b>)
    }
    return ''
  }

  render() {
    let vaildation = Yup.object().shape({})
    if (!this.props.public_user.user) {
      vaildation = Yup.object().shape({
        password: Yup.string()
          .min(4, 'Too Short!')
          .required('Required')
      });
    }

    return (
      <Row>
        <Col sm={12} className="d-flex justify-content-center ">
          <Formik enableReinitialize onSubmit={this.submitPreferences} initialValues={this.props.public_user} validationSchema={vaildation}>
            {(props) => (
              <Form noValidate onSubmit={props.handleSubmit}>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h3 className="panel-title">User Preferences</h3>
                  </div>
                  <div className="panel-body">
                    <Col>
                      <Form.Group controlId="formBasicName">
                        {this.renderAccount()}
                        {this.renderMailPref()}
                        {this.renderAllTags()}

                        <Row>
                          <Col sm={3}>
                            <Button variant="primary" type="submit">
                              Save Preferences
                            </Button>
                          </Col>
                          <Col>
                            {this.savedMessage()}
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                  </div>
                </div>

              </Form>
            )}
          </Formik>
        </Col>
      </Row>


    );
  }
}

const mapStateToProps = (state) => ({
  ...state.entities
})


export default connect(
  mapStateToProps, actions
)(Preferences);
