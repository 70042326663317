import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';


class PuzzleCard extends React.Component {
  render() {
    const puzzle = this.props.puzzle;
    return (
      <Card>
        <Card.Header>
          <Link className="postTitle" to={`/puzzles/${puzzle.slug}`}>{puzzle.title}</Link>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={2} sm={0} />
            <Col md={8} sm={12}>
              <Link to={`/puzzles/${puzzle.slug}`}>
                {puzzle.puzzle_image ? <img style={{ width: '100%' }} src={puzzle.puzzle_image.url} alt={puzzle.title} /> : <div />}
              </Link>
            </Col>
            <Col md={2} sm={0} />
          </Row>
        </Card.Body>
      </Card>
    );
  }
}


export default PuzzleCard;
