import React, { useEffect, useState } from 'react';

CanvasRenderingContext2D.prototype.wrapText = function (text, x, y, maxWidth, lineHeight) {
  const lines = text.split('\n');

  for (let i = 0; i < lines.length; i++) {
    const words = lines[i].split(' ');
    let line = '';

    for (let n = 0; n < words.length; n++) {
      const testLine = `${line + words[n]} `;
      const metrics = this.measureText(testLine);
      const testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        this.fillText(line, x, y);
        line = `${words[n]} `;
        y += lineHeight;
      } else {
        line = testLine;
      }
    }

    this.fillText(line, x, y);
    y += lineHeight;
  }
}

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1)
}


const WheelComponent = ({
  segments,
  seg_colors,
  winning_segment,
  onFinished,
  primaryColor,
  contrastColor,
  buttonText,
  fontSize
}) => {
  let current_segment = ''
  let isStarted = false;
  const [isFinished, setFinished] = useState(false)
  let timerHandle = 0
  const timerDelay = segments.length
  let angleCurrent = 0
  let angleDelta = 0
  const size = 340
  let canvasContext = null
  let maxSpeed = Math.PI / (segments.length * 300)
  const upTime = segments.length * 10;
  const downTime = segments.length * 200;
  let spinStart = 0
  let frames = 0
  const centerX = 400
  const centerY = 400
  useEffect(() => {
    wheelInit();
    setTimeout(() => {
      window.scrollTo(0, 1)
    }, 0)
  }, [])
  const wheelInit = () => {
    initCanvas()
    wheelDraw()
  }

  const initCanvas = () => {
    let canvas = document.getElementById('canvas')
    if (navigator.appVersion.indexOf('MSIE') !== -1) {
      canvas = document.createElement('canvas')
      canvas.setAttribute('width', 1000)
      canvas.setAttribute('height', 600)
      canvas.setAttribute('id', 'canvas')
      document.getElementById('wheel').appendChild(canvas)
    }
    canvas.addEventListener('click', spin, false)
    canvasContext = canvas.getContext('2d')
  }
  const spin = () => {
    isStarted = true;
    if (timerHandle === 0) {
      spinStart = new Date().getTime()
      // maxSpeed = Math.PI / ((segments.length*2) + Math.random())
      maxSpeed = Math.PI / ((segments.length) * 3)
      frames = 0
      timerHandle = setInterval(onTimerTick, timerDelay)
    }
  }
  const onTimerTick = () => {
    frames++
    draw()
    const duration = new Date().getTime() - spinStart
    let progress = 0
    let finished = false
    if (duration < upTime) {
      progress = duration / upTime
      angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2)
    } else {
      if (winning_segment) {
        if (current_segment === winning_segment && frames > segments.length * 20) {
          angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2)
          progress = 1
        } else {
          progress = duration / downTime
          angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2)
        }
      } else {
        progress = duration / downTime
        angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2)
      }
      if (progress >= 1) finished = true
    }

    angleCurrent += angleDelta
    while (angleCurrent >= Math.PI * 2) angleCurrent -= Math.PI * 2
    if (finished) {
      setFinished(true);
      onFinished(current_segment);
      clearInterval(timerHandle);
      timerHandle = 0;
      angleDelta = 0;
    }
  }

  const wheelDraw = () => {
    clear()
    drawWheel()
    drawNeedle()
  }

  const draw = () => {
    clear()
    drawWheel()
    drawNeedle()
  }

  const drawSegment = (key, lastAngle, angle) => {
    const ctx = canvasContext
    const value = segments[key]
    ctx.save()
    ctx.beginPath()
    ctx.moveTo(centerX, centerY)
    ctx.arc(centerX, centerY, size, lastAngle, angle, false)
    ctx.lineTo(centerX, centerY)
    ctx.closePath()
    ctx.fillStyle = seg_colors[key]
    ctx.fill()
    ctx.stroke()
    ctx.save()
    ctx.translate(centerX, centerY)
    ctx.rotate((lastAngle + angle) / 2)
    ctx.fillStyle = contrastColor || 'white'
    ctx.font = `bold ${fontSize}px proxima-nova`
    ctx.fillText(value.trim().substr(0, 21).capitalize(), size / 2 + 20, 0)
    ctx.restore()
  }

  const drawWheel = () => {
    const ctx = canvasContext
    let lastAngle = angleCurrent
    const len = segments.length
    const PI2 = Math.PI * 2
    ctx.lineWidth = 1
    ctx.strokeStyle = primaryColor || 'black'
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'center'
    ctx.font = '1em proxima-nova'
    for (let i = 1; i <= len; i++) {
      const angle = PI2 * (i / len) + angleCurrent
      drawSegment(i - 1, lastAngle, angle)
      lastAngle = angle
    }

    // Draw a center circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, 80, 0, PI2, false);
    ctx.closePath();
    ctx.fillStyle = primaryColor || 'black';
    ctx.lineWidth = 10;
    ctx.strokeStyle = contrastColor || 'white';
    ctx.fill();
    ctx.font = 'bold 1.8em proxima-nova';
    ctx.fillStyle = contrastColor || 'white';
    ctx.textAlign = 'center';
    const txt = buttonText || 'Spin'
    ctx.wrapText(txt, centerX, centerY - 20, 140, 27)
    ctx.stroke();

    // Draw outer circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, size, 0, PI2, false);
    ctx.closePath();

    ctx.lineWidth = 10;
    ctx.strokeStyle = primaryColor || 'black';
    ctx.stroke();
  }

  const drawNeedle = () => {
    const ctx = canvasContext
    ctx.lineWidth = 1
    ctx.strokeStyle = contrastColor || 'white'
    ctx.fileStyle = contrastColor || 'white'
    ctx.beginPath()
    ctx.moveTo(centerX + 18, centerY - 80)
    ctx.lineTo(centerX - 18, centerY - 80)
    ctx.lineTo(centerX, centerY - 140)
    ctx.closePath()
    ctx.fill()
    const change = angleCurrent + Math.PI / 2
    let i = segments.length
      - Math.floor((change / (Math.PI * 2)) * segments.length)
      - 1
    if (i < 0) i += segments.length
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillStyle = primaryColor || 'black';
    ctx.font = 'bold 1.5em proxima-nova'
    current_segment = segments[i]
    isStarted && ctx.fillText(current_segment, centerX + 10, centerY + size + 50)
  }
  const clear = () => {
    const ctx = canvasContext
    ctx.clearRect(0, 0, 1000, 800)
  }
  return (
    <div id="wheel">
      <canvas
        id="canvas"
        width="1000"
        height="800"
        style={{
          pointerEvents: isStarted ? 'none' : 'auto'
        }}
      />
    </div>
  )
}

export default WheelComponent
