import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FacebookProvider, Like } from 'react-facebook';
import MetaTags from 'react-meta-tags';
import { connect } from 'react-redux';
import * as actions from '../actions/entity_actions';

class PuzzleDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showAnswer: false }
  }

  componentDidMount() {
    this.props.getEntityByField('puzzle', 'slug', this.props.match.params.id);
  }

  renderAnswer(puzzle) {
    if (!this.state.showAnswer) return <div />
    return (
      <>
        <Row className="my-5">
          <Col>
            <div dangerouslySetInnerHTML={{ __html: puzzle.answer }} />
          </Col>
        </Row>
        {this.renderAnwerImages(puzzle.answer_images)}
      </>
    )
  }

  renderAnwerImages(images) {
    return images.map((image) => <Row className="my-2"><Col><img className="puzzleImage" src={image.url} alt={image.name} /></Col></Row>)
  }


  render() {
    const puzzle = this.props.puzzle
    if (!puzzle.puzzle_image) return <div />
    return (
      <Row>
        <MetaTags>
          <title>
            Math Curious -
            {puzzle.title}
          </title>
          <meta property="og:title" content={`Math Curious - ${puzzle.title}`} />
          <meta property="og:description" content={`Math Curious - ${puzzle.title}`} />
          <meta property="og:image" content={puzzle.puzzle_image.url} />
        </MetaTags>
        <Col md={12} sm={12}>
          <h3 className="postTitle">
            {puzzle.title}
          </h3>
          <FacebookProvider appId="258572075331216">
            <Like href={`https://app.mathcurious.com${this.props.location.pathname}`} showFaces share />
          </FacebookProvider>
          <Row className="my-2">
            <div dangerouslySetInnerHTML={{ __html: puzzle.description }} />
          </Row>
          <Row><img src={puzzle.puzzle_image.url} alt={puzzle.title} className="puzzleImage" /></Row>
          <Row className="my-5">
            <Button onClick={() => { this.setState({ showAnswer: true }) }}>View Answer</Button>
          </Row>
          {this.renderAnswer(puzzle)}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.entities
})

export default connect(
  mapStateToProps, actions
)(PuzzleDetail);
