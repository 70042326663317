import pluralize from 'pluralize';

const initialState = {
  puzzles: [],
  puzzle: {},
  spinner: { segments: '1,4', colors: '#FF0000, #FFFFFF' },
  public_user: {},
  bingo_list: {}
}
const entities = (state = initialState, action) => {
  let name = ''
  switch (action.type) {
    case 'LOAD_ENTITIES_SUCCESS':
      name = pluralize(action.meta.previousAction.payload.request.modelName);
      return { ...state, [name.replace('-', '_')]: action.payload.data }
    case 'LOAD_ENTITY_SUCCESS':
      name = action.meta.previousAction.payload.request.modelName;
      return { ...state, [name.replace('-', '_')]: action.payload.data }
    case 'LOAD_ENTITY_BY_FIELD_SUCCESS':
      name = action.meta.previousAction.payload.request.modelName;
      return { ...state, [name.replace('-', '_')]: action.payload.data[0] }
    case 'ADD_ENTITY_SUCCESS':
      name = action.meta.previousAction.payload.request.modelName;
      return { ...state, [name]: action.payload.data }

    default:
      return state;
  }
};

export default entities;
