import React from 'react';
import { Col, Row } from 'react-bootstrap';
import MetaTags from 'react-meta-tags';
import { connect } from 'react-redux';
import * as actions from '../actions/entity_actions';
import PuzzleCard from '../components/PuzzleCard';


class Puzzles extends React.Component {
  componentDidMount() {
    this.props.getEntities('puzzles');
  }

  renderPuzzles(puzzles) {
    if (puzzles.length === 0) return ''
    const grid = []
    for (let i = 0; i < puzzles.length; i += 2) {
      grid.push(this.renderTwoPuzzles(puzzles[i], puzzles[i + 1]))
    }
    return grid
  }

  renderTwoPuzzles(puzzle1, puzzle2) {
    return (
      <Row className="my-5">
        <Col sm={12} md={6}>
          <PuzzleCard key={puzzle1.id} puzzle={puzzle1} />
        </Col>
        <Col sm={12} md={6}>
          {puzzle2 ? <PuzzleCard key={puzzle2.id} puzzle={puzzle2} /> : <div />}
        </Col>
      </Row>
    )
  }

  render() {
    return (

      <div>
        <MetaTags>
          <title>
            Math Curious - Puzzles
          </title>
          <meta property="og:title" content="Math Curious - Puzzles" />
          <meta property="og:description" content="Math Curious - Puzzles" />
          <meta property="og:image" content="/puzzles_banner.jpg" />
        </MetaTags>
        <h3>Puzzles </h3>
        <div>
          <img style={{ width: '100%' }} alt="puzzles" src="/puzzles_banner.jpg" />
          <p>&nbsp;</p>
          <p>
            Puzzles sharpen our brains and teach us perseverance.
            They enhance our problem-solving skills as we are challenged to think and create strategies.
          </p>

          <p>Try these puzzles with your children or students. </p>

        </div>
        {this.renderPuzzles(this.props.puzzles)}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.entities
})

export default connect(
  mapStateToProps, actions
)(Puzzles);
