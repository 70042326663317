import { Formik } from 'formik';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../actions/worksheets';
import SelectField from '../common/SelectField';
import TextField from '../common/TextField';

const querystring = require('querystring');


class TwistedTimeTables extends React.Component {
  getWorkSheet = (values) => {
    const url = `https://api.mathcurious.com/worksheets/twisted_time_tables?${querystring.stringify(values)}`
    window.open(url, '_new');
  }


  render() {
    return (
      <Row>
        <Col sm={8}>
          <Formik enableReinitialize onSubmit={this.getWorkSheet} initialValues={{ size: 5, level: 1, num: 10, min: 2, max: 9 }}>
            {(props) => (
              <Form noValidate onSubmit={props.handleSubmit}>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h3 className="panel-title">Twisted Time Tables Work Sheets</h3>
                  </div>
                  <div className="panel-body">
                    <Col sm={6}>
                      <Form.Group controlId="formBasicName">

                        <TextField name="size" label="Size of the table" placeholder="" />
                        <TextField name="num" label="How many tables?" placeholder="" />
                        <TextField name="min" label="Minimum number on the table" placeholder="" />
                        <TextField name="max" label="Maximum number on the table" placeholder="" />
                        <SelectField name="level" label="Difficlty level" placeholder="">
                          {[{ value: 1, label: 1 }, { value: 2, label: 2 }]}
                        </SelectField>

                        <Button variant="primary" type="submit">
                          Get Worksheet
                        </Button>
                      </Form.Group>
                    </Col>
                  </div>
                </div>

              </Form>
            )}
          </Formik>
        </Col>
      </Row>


    );
  }
}


export default connect(
  null, actions
)(TwistedTimeTables);
