import { Formik } from 'formik';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../actions/entity_actions';


class UnSubscribe extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      saved: false
    }
  }

  componentDidMount() {
    this.props.getEntityByField('public-user', 'token', this.props.match.params.token);
  }

  submitPreferences = (values) => {
    console.log(values)
    values.mail_pref = 'NONE'
    values.unsub = true
    this.props.updateEntity('public-user', values, 'none')
    this.setState({ saved: true })
  }


  render() {
    if (this.state.saved) {
      return (
        <Row>
          <Col className="d-flex justify-content-center  mt-5">

            <h3>  You have been unsubscribed.</h3>

          </Col>
        </Row>
      )
    }
    return (
      <Row>

        <Col sm={12} className="d-flex justify-content-center">
          <Formik enableReinitialize onSubmit={this.submitPreferences} initialValues={this.props.public_user}>
            {(props) => (
              <Form noValidate onSubmit={props.handleSubmit}>
                <div className="panel panel-default ">
                  <div className="panel-heading" />
                  <div className="panel-body">
                    <Row>
                      <Col className="d-flex justify-content-center  mt-5">
                        <Button variant="primary" type="submit">
                          Unsubscribe
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex justify-content-center mb-2 mt-4">
                        <h5>Are we sending too many emails ?</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex justify-content-center mb-4 mt-2">
                        <h5>Do you want to change notification preferences and recieve only posts which are relevant to you ?</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="d-flex justify-content-center pb-5">
                        <a className="btn btn-primary" href={`/settings/${this.props.match.params.token}`}>
                          Yes, Change My Notification Preferences
                        </a>
                      </Col>
                    </Row>
                  </div>
                </div>

              </Form>
            )}
          </Formik>
        </Col>

      </Row>


    );
  }
}

const mapStateToProps = (state) => ({
  ...state.entities
})


export default connect(
  mapStateToProps, actions
)(UnSubscribe);
