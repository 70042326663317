import React from 'react';
import { Switch, withRouter } from 'react-router-dom';
import MainRoute from './layout/MainRoute';
import AddBingoList from './pages/AddBingoList';
import Preferences from './pages/Preferences';
import PuzzleDetail from './pages/PuzzleDetail';
import Puzzles from './pages/Puzzles';
import Spinner from './pages/Spinner';
import TwistedTimeTables from './pages/TwistedTimeTables';
import UnSubscribe from './pages/UnSubscribe';
import Signup from './Signup';

const Routes = () => (

  <Switch>
    <MainRoute path="/puzzles/:id" component={PuzzleDetail} />

    <MainRoute path="/puzzles" component={Puzzles} />

    <MainRoute path="/bingocards/:id" component={AddBingoList} />

    <MainRoute path="/spinner/:id" component={Spinner} />

    <MainRoute path="/worksheets/twisted_time_tables" component={TwistedTimeTables} />

    <MainRoute path="/signup" component={Signup} />
    <MainRoute path="/settings/:token" component={Preferences} />
    <MainRoute path="/unsubscribe/:token" component={UnSubscribe} />

    <MainRoute path="/" component={Puzzles} />


  </Switch>

)

export default withRouter(Routes);
