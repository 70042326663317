import React from 'react';


class Header extends React.Component {
  render() {
    return (
      <header>
        <div className="header_image" />

        <nav className="home-nav ">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 top-menu">
                <div className="header-logo">
                  <a href="https://mathcurious.com/" title="rel=&quot;home&quot;" alt="Menu Style" className="header-logo-tagline">
                    <h3 className="site-title" />
                    <span className="site-description" />
                  </a>
                  {' '}

                </div>
                <div id="cssmenu">
                  <div id="menu-line" style={{ width: '79px' }} />
                  <div id="menu-button" />
                  <ul id="menu-main-menu" className="menu">
                    <li id="menu-item-452" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-452"><a href="https://mathcurious.com/" aria-current="page">Home</a></li>
                    <li id="menu-item-1474" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1474"><a href="https://mathcurious.com/worksheets/">Worksheets</a></li>
                    <li id="menu-item-1478" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1478"><a href="https://mathcurious.com/mathematical-picture-and-story-books/">Mathematical Story Books</a></li>
                    <li id="menu-item-1480" className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-1480"><a href="https://mathcurious.com/category/print-to-play/">Print to Play</a></li>
                    <li id="menu-item-1825" className="menu-item menu-item-type-post_type menu-item-object-post menu-item-1825"><a href="https://app.mathcurious.com/puzzles/">Puzzles</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
