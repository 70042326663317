import '@djthoms/pretty-checkbox';
import { Field } from 'formik';
import React from 'react';


export default function RadioButton({ id, name, className, label, value }) {
  return (
    <label style={{ paddingRight: '40px' }}>
      <Field
        name={name}
        type="radio"
        className={className}
        value={value}
      />

      {'  '}
      {label}
    </label>
  );
}
