import React from 'react';
import MetaTags from 'react-meta-tags';
import { connect } from 'react-redux';
import * as actions from '../actions/entity_actions';
import WheelComponent from '../common/WheelComponent';


class Spinner extends React.Component {
  componentDidMount() {
    this.props.getEntityByField('spinner', 'slug', this.props.match.params.id);
  }

  onFinished = (winner) => {
    console.log(winner);
  }

  render() {
    const segColors = this.props.spinner.colors.split(',')
    const segments = this.props.spinner.segments.split(',')


    if (segments.length < 3) return (<div />)
    return (
      <div>
        <MetaTags>
          <title>
            Math Curious - Spinner
          </title>
          <meta property="og:title" content="Math Curious - Puzzles" />
          <meta property="og:description" content="Math Curious - Puzzles" />
          <meta property="og:image" content="/puzzles_banner.jpg" />
        </MetaTags>
        <h3>
          {this.props.spinner.Name}
        </h3>
        <div>
          <WheelComponent
            segments={segments}
            seg_colors={segColors}
            onFinished={(winner) => this.onFinished(winner)}
            primaryColor="black"
            contrastColor="white"
            buttonText={this.props.spinner.title}
            fontSize={this.props.spinner.font_size}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.entities
})

export default connect(
  mapStateToProps, actions
)(Spinner);
