import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Route } from 'react-router-dom';
import Header from './Header';

class MainLayout extends React.Component {
  render() {
    return (
      <div id="wrapper">
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header />
            <Row className="my-1"><Col /></Row>
            <div className="container">
              { this.props.children }
            </div>
          </div>
          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>Copyright &copy; https://mathcurious.com 2020</span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}


const MainRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(matchProps) => (
      <MainLayout>
        <Component {...matchProps} />
      </MainLayout>
    )}
  />
);

export default MainRoute;
