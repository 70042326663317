import { createAction } from 'redux-actions';

const querystring = require('querystring');


export const getTwistedTimeTables = createAction('LOAD_TWISTED_TIME_TABLES', (input) => ({
  request: {
    url: `/worksheets/twisted_time_tables?${querystring.stringify(input)}`
  }
}));

export const getBingoCards = createAction('LOAD_BINGO_CARDS', (input) => ({
  request: {
    url: `/worksheets/bingocards?${querystring.stringify(input)}`
  }
}));
