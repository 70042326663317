import '@djthoms/pretty-checkbox';
import { Field } from 'formik';
import { Switch } from 'pretty-checkbox-react';
import React from 'react';


export default function Checkbox({ id, name, className, label }) {
  return (
    <>
      <Field
        name={name}
        render={
                ({ field, form }) => (
                  <Switch
                    color="primary"
                    shape="fill"
                    id={id}
                    className={className}
                    checked={
                        field.value
                    }
                    {...field}
                  >
                    {label}
                  </Switch>

                )
            }
      />
    </>
  );
}
