import { Formik } from 'formik';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../actions/entity_actions';
import FormWrapper from '../common/FormWrapper';
import TextField from '../common/TextField';


class AddBingoList extends React.Component {
  constructor(props) {
    super(props)
    this.isNew = false
  }

  saveBingoList = (values) => {
    if (this.props.match.params.id === 'new') {
      this.props.addEntity('bingo_list', values, 'none')
    }
  }

  componentDidMount() {
    this.setNew(this.props.match.params.id)
  }

  setNew(id) {
    if (id === 'new') {
      this.isNew = true
    } else {
      this.isNew = false
    }
  }

  renderLink() {
    if (this.props.bingoList.id) {
      return (
        <a className="btn btn-primary" href={`https://api.mathcurious.com/bingocards/${this.props.bingoList.id}`}>
          Download
        </a>
      )
    }
    return ''
  }

  render() {
    return (
      <FormWrapper title="List" isNew={this.isNew}>
        <Formik enableReinitialize onSubmit={this.saveBingoList} initialValues={this.props.bingoList}>
          {(props) => (
            <Form noValidate onSubmit={props.handleSubmit}>
              <Form.Group controlId="formBasicName">
                <TextField name="words" label="Cell Options (separate by comma)" placeholder="" />
                <Button variant="primary" type="submit">
                  Save
                </Button>
                {this.renderLink()}
              </Form.Group>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  bingoList: state.entities.bingo_list,
  ...state.entities
})

export default connect(
  mapStateToProps, actions
)(AddBingoList);
